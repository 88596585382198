import logo from './logo.svg';
import './App.css';
import {useState, useEffect, useLayoutEffect, useRef} from 'react';
import { BsFillSendFill } from 'react-icons/bs'
import { handleLogout } from './firebase';

let API_KEY = "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"
export const UserQuestion = ({question}) => {
  console.log(question)
  return(
    <div className='w-full py-6 border-b border-black/10'>
      <div className='w-11/12 sm:w-4/5 lg:w-3/5 mx-auto'>
        <p className=' w-full'>{question}</p>
      </div>
    </div>
  )
}

export const ChatAnswer = ({answer}) => {
  return(
    <div className='w-full bg-gray-50 border-b py-6 border-black/10'>
      <div className='w-11/12 sm:w-4/5 lg:w-3/5 mx-auto'>
        <p className='w-full wx-auto whitespace-pre-line'>{answer}</p>
      </div>
    </div>
  )}


const Chat = ({userData}) => {
  const [message, setMessage] = useState("")
  const textareaRef = useRef(null);
  const divRef = useRef(null);
  const messageRef = useRef(null);
  const [chatLog, setChatLog] = useState([])
  const [logout, setLogout] = useState(false)

  useEffect(() => {
    console.log(userData)
  }, [userData])
  


  const handleSubmit = (e) => {
    e.preventDefault()
    let gptData = {"model": "gpt-3.5-turbo", "messages": [...chatLog, {"role": "user", "content": message}], "temperature": 0.7}
    setChatLog([...chatLog, {"role": "user", "content": message}])
    let chatLogCache = [...chatLog, {"role": "user", "content": message}]
    setMessage("")
    fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      headers: {
        "Authorization": "Bearer " + API_KEY,
        "Content-Type": "application/json"
      },
      body: JSON.stringify(gptData)
    }).then((data) => {return data.json();}).then((data) => {
      const str = data.choices[0].message.content; 
      const contentmessage = str.replace(/^\n+|\n+$/g, "");
      setChatLog([...chatLogCache, {"role": "assistant", "content": contentmessage}])})
  }

  useLayoutEffect(() => {
    // Reset height - important to shrink on delete
    textareaRef.current.style.height = "inherit";
    divRef.current.style.height = "inherit";
    // Set height
    textareaRef.current.style.height = `${Math.max(
      textareaRef.current.scrollHeight,
      60
    )}px`;
    divRef.current.style.height = `${Math.max(
      textareaRef.current.scrollHeight,
      60
    )}px`;

    messageRef.current.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start"
    });

  }, [message]);



  return (
    <div className="absolute w-full flex h-full">
      <div className="min-h-full w-72 md:w-80 bg-zinc-800 hidden sm:block relative">
        <h1 className='text-white inline-block text-center w-full font-bold mt-2'>ChatGPT PHG EDITION</h1>
        {logout && <a href='#' onClick={handleLogout} className='flex items-center justify-center absolute left-[10%] bottom-[72px] w-4/5 h-14 bg-zinc-600 rounded-sm'>
          <span className='text-white font-bold text-[17px] pb-1 ml-3'>Logout</span>
        </a>}
        <a href='#' onClick={e => setLogout(!logout)} className='absolute hover:drop-shadow-md bottom-4 focus:outline outline-1 outline-gray-400 left-[10%] w-4/5 h-14 bg-zinc-700 rounded-md flex items-center overflow-hidden'>
            <img src={userData.photoURL} className="h-full rounded-md" />
            <span className='text-white font-bold text-[17px] pb-1 ml-3'>{userData.displayName}</span>
        </a>
      </div>
      <div className="relative min-h-full w-full bg-grey-50">
        <div ref={messageRef} className='max-h-[calc(100vh-80px)] overflow-scroll'>
          {chatLog.map((data) => {
            return(
              <>
                {(data.role == "user") ? <UserQuestion question={data.content} /> : <ChatAnswer answer={data.content} />}
              </>
            )
          })}
        </div>
        <div className='group absolute bottom-0 w-full py-3 bg-white z-3 border-t border-black/10'>
          <div ref={divRef} className='w-4/5 sm:w-3/4 mx-auto bg-white h-auto min-h-[60px] max-h-[160px] group-focus:outline-gray-300 rounded-md shadow-[0_0_10px_rgba(0,0,0,0.10)] border-black/10 border'>
            <textarea ref={textareaRef} style={{minHeight: 60, resize: "none"}} value={message} onChange={e => setMessage(e.target.value)} 
              className='rounded py-2 px-3 bg-transparent w-[calc(100%-50px)] float-left max-h-[160px] z-2 resize-none focus:border-none group focus:border focus:outline-none' 
            />
            <a href="#" onClick={e => {handleSubmit(e)}}><BsFillSendFill  size={25} className="float-left ml-3 mt-4 " /></a>
          </div>
        </div>

      </div>
    </div>
  );
}
export default Chat;