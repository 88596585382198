import React, {useEffect, useState} from 'react'
import Chat from './chat'
import Login from './login';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';


const App = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    console.log(user)
    firebase.auth().onAuthStateChanged(user => {
      console.log(user)
      setUser(user);
    })
  }, [])


  console.log(user);

  return (
    <Router>
      <Routes>
        <Route path="/chat" element={(user == null) ? <Navigate to="/" replace/> : <Chat userData={user} />} />
        <Route path="*" element={<Navigate to="/" replace />} />
        <Route path="/" element={(user != null) ? <Navigate to="/chat" /> : <Login />} />
      </Routes>
    </Router>
  )}

export default App;