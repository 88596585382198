import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDRSoDnWFbCpBOorwHe4KoSh9G2V4_hVsU",
  authDomain: "chat-gpt-clone-b778a.firebaseapp.com",
  projectId: "chat-gpt-clone-b778a",
  storageBucket: "chat-gpt-clone-b778a.appspot.com",
  messagingSenderId: "412749056184",
  appId: "1:412749056184:web:19f3b79fb44ee541be8c4d",
  measurementId: "G-JY1KTRST3X"
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();

const provider = new firebase.auth.GoogleAuthProvider();
provider.setCustomParameters({ prompt: 'select_account' });

export const signInWithGoogle = () => auth.signInWithRedirect(provider);
export const handleLogout =() => {
  auth.signOut();
}
  
export default firebase;