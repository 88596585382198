

import React from 'react'
import { signInWithGoogle } from './firebase'

const Login = () => {
  const handleLogin = () => {
    console.log("test")
    
  }
  return (
    <div className='flex justify-center items-center w-[100vw] bg-slate-700 h-[100vh] flex-col gap-4 '>
        <h1 className='text-white text-3xl font-extrabold'>ChatGPT PHG Edition</h1>
        <p className='text-white'>Please don't ban this app. Thank you.</p>
        <button className="button bg-white px-2 py-1" onClick={signInWithGoogle}><i></i>Sign in with google</button>
    </div>
  )
}

export default Login